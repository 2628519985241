import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import gov from '../../../../assets/logo-gov.png';

export default function BarraGov() {
  return (
    <AppBar  color="primary">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
          <a href="https://www.gov.co/" target="_blank" rel="noopener noreferrer">
            <img src={gov} alt="Img Not found" width="150px" />
          </a>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
